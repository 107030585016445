<template>
	<section class="container mx-auto grid h-full auto-rows-auto grid-cols-1">
		<h2
			class="row-start-1 mb-3 border border-dashed border-transparent border-b-gray-400 pb-2 pt-2 text-3xl font-semibold capitalize text-gray-500 dark:text-gray-300"
		>
			<slot name="title">{{ computedTitlePrefix }} {{ computedTitle }}</slot>
		</h2>

		<p v-if="$slots.description" class="mb-1 pb-0">
			<slot name="description" />
		</p>

		<slot name="content" />

		<footer
			v-if="editing && showActions"
			class="mt-2 flex flex-col gap-2 space-x-2 border border-dashed border-transparent border-t-gray-400 pt-2 sm:flex-row sm:justify-end sm:gap-0"
		>
			<slot name="actions" />
			<BaseButton
				:disabled="!validationFlags?.changed || savingChanges"
				type="reset"
				color="gray"
			>
				Cancel Changes
			</BaseButton>

			<BaseButton
				color="primary"
				:disabled="validationFlags?.invalid || savingChanges.value || !hasChanges"
				type="submit"
			>
				Save Changes
			</BaseButton>
		</footer>
	</section>
</template>

<script setup>
import { computed } from 'vue';
import { EditorViews } from '@/views/MXEditor/views';
import useEditorChangesStore from '@/stores/editorChanges';
import { storeToRefs } from 'pinia';
import BaseButton from '@/components/ui/BaseButton';
import { useRoute } from 'vue-router/composables';
const route = useRoute();

const props = defineProps({
	title: { type: String, default: undefined },
	titlePrefix: { type: String, default: undefined },
	showActions: { type: Boolean, default: true },
	cancelAction: { type: Function, default: () => {} },
	saveAction: { type: Function, default: undefined },
});

const editorChangesStore = useEditorChangesStore();
const { savingChanges, validationFlags, hasChanges } = storeToRefs(editorChangesStore);

const currentView = computed(() => EditorViews.find(({ path }) => path === route.params.editor));
const editing = computed(() => route.name === 'edit');
const computedTitlePrefix = computed(
	() => props.titlePrefix || (editing.value ? 'Editing' : 'Help for')
);
const computedTitle = computed(
	() =>
		props.title || ((currentView.value?.header || currentView.value?.label) ?? 'TITLE UNKNOWN')
);
</script>

<style scoped></style>
