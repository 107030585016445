import { createPinia } from 'pinia';

const pinia = createPinia();

export function patch$resetFunction({ store }) {
	const initialState = structuredClone(store.$state);

	store.$reset = async () => {
		store.$patch($state => {
			Object.assign($state, initialState);
		});
		await store.onReset?.();
	};
}

pinia.use(patch$resetFunction);

export default pinia;
