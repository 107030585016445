import { extend } from 'vee-validate';
import {
	required,
	email,
	digits,
	required_if,
	min_value,
	max_value,
	integer,
	numeric,
	length,
	min,
	max,
	between,
	regex,
	size,
} from 'vee-validate/dist/rules';
import { number, expirationYear, expirationMonth, expirationDate } from 'card-validator';
import { isTime } from '@/utils/MXEditor/time';
import { tagStripper } from '@/utils';

export function setupValidation() {
	extend('card_expiration_month', {
		params: ['year'],
		validate(value, { year }) {
			const { isValid, isPotentiallyValid } = expirationMonth(value);
			const { isValid: dateIsValid } = expirationDate({ month: value, year });

			if (year === null || year.length !== 4) {
				return true;
			}
			if (dateIsValid) {
				return true;
			} else if (isValid) {
				return `This month/year combo is not valid`;
			} else if (!isPotentiallyValid) {
				return `Not a valid month`;
			}
		},
	});
	extend('card_expiration_year', {
		params: ['month'],
		validate(value, { month }) {
			const yearInfo = expirationYear(value);
			const dateInfo = expirationDate({ month, year: value });
			const { isValid: yearIsValid, isPotentiallyValid } = yearInfo;
			const { isValid: dateIsValid } = dateInfo;

			if (dateIsValid) {
				if (yearIsValid) {
					return value.length === 4 ? true : '{_field_} must be 4 digits long.';
				}
			} else if (isPotentiallyValid) {
				return true;
			} else {
				return `Not a valid year`;
			}
		},
	});
	extend('card_security_code', {
		params: ['number'],
		validate(value, { number: card_number }) {
			const cardInfo = number(card_number);
			const { card } = cardInfo;

			if (!card) {
				return true;
			}
			if (card.code.size === value.length) {
				return true;
			} else {
				return `${card.code.name} should be ${card.code.size} digits.`;
			}
		},
	});
	extend('compliance', {
		params: ['parsedResult'],
		async validate(value, { parsedResult }) {
			if (tagStripper(value) === '') {
				return true;
			}

			try {
				const { isSanitized, isCompliant } = parsedResult.value;
				if (
					(isCompliant && isSanitized) ||
					// default to true if compliance hasn't run yet
					// This was breaking editors that have multiple change types
					(isCompliant === undefined && isSanitized === undefined)
				) {
					return true;
				}
				return `Some of this text is not compliant. Please remove or reword the following:`;
			} catch (error) {
				console.error(error);
				return 'The compliance check failed. Please try again.';
			}
		},
	});
	extend('credit_card', {
		validate(value) {
			const { card, isPotentiallyValid, isValid } = number(value);

			if (isValid) {
				return true;
			} else {
				if (!Number.isInteger(value)) {
					return `Card number should be numeric.`;
				}
				if (isPotentiallyValid) {
					if (card) {
						if (!card.lengths.includes(value.length)) {
							return `${card.niceType} cards should be ${new Intl.ListFormat('en', {
								style: 'long',
								type: 'disjunction',
							}).format(card.lengths.map(l => l.toString()))} digits.`;
						}
					}
				} else {
					return '{_field_} does not appear to be a valid credit card number.';
				}
			}
		},
	});

	extend('email', email);
	extend('digits', digits);

	extend('image', {
		validate: files => {
			const regex = new RegExp(`image/.+$`, 'i');

			// cast FileList type to an Array, then check each for a match
			return [...files].every(file => regex.test(file.type));
		},
		message: `That doesn't appear to be a valid image file.`,
	});
	extend('dimensions', {
		validate(
			files,

			{
				tooSkinny = true,
				tooShort = true,
				minHeight = 200,
				minWidth = 200,
				croppedHeight,
				croppedWidth,
			}
		) {
			if (tooSkinny && tooShort) {
				return `{_field_} must be at least ${minWidth}px wide and ${minHeight}px high. Current size is ${croppedWidth}px wide by ${croppedHeight}px high.`;
			}
			if (tooSkinny) {
				return `{_field_} must be at least ${minWidth}px wide. Current size is ${croppedWidth}px wide.`;
			}

			if (tooShort) {
				return `{_field_} must be at least ${minHeight}px high. Current size is ${croppedHeight}px high`;
			}
			return true;
		},
		params: ['tooSkinny', 'tooShort', 'minHeight', 'minWidth', 'croppedHeight', 'croppedWidth'],
	});
	extend('dedupe', {
		params: ['list'],
		validate(item, { list = [] }) {
			if (list.includes(item.trim())) {
				return `{_field_} should be unique. It is already included in the list.`;
			}
			return true;
		},
	});
	extend('between', {
		...between,
		validate(value, { min, max }) {
			return value >= min && value <= max;
		},
		params: ['min', 'max'],
		message: `{_field_} must be between {min} and {max}.`,
	});
	extend('numeric', { ...numeric, message: `{_field_} should only consist of numbers.` });
	extend('integer', { ...integer, message: `{_field_} should only consist of numbers.` });
	extend('length', {
		...length,
		params: ['length'],
		message: `{_field_} should be {length} characters.`,
	});
	extend('min', {
		...min,
		params: ['length'],
		message: `{_field_} must be at least {length} characters.`,
	});
	extend('min_value', {
		...min_value,
		validate(value, { amount }) {
			return value >= amount;
		},
		params: ['amount'],
		message: `{_field_} must be at least {amount}.`,
	});
	extend('max_value', max_value);
	extend('max', {
		...max,
		params: ['length'],
		message: `{_field_} must be no more than {length} characters.`,
	});
	extend('office_hours', {
		params: ['ranges', 'start', 'close'],
		validate(value) {
			if (
				(Array.isArray(value) &&
					value.every(range => isTime(range.start) && isTime(range.close))) ||
				isTime(value)
			) {
				return true;
			} else {
				return `Please choose valid times`;
			}
		},
	});
	extend('required_if', required_if);
	// Override the default message.
	extend('required', {
		...required,
		message: '{_field_} is required',
	});
	extend('regex', regex);
	extend('size', {
		...size,
		params: ['limit', 'currentSize'],

		validate: (value, { limit = 1000, currentSize }) => {
			const nSize = limit * 1024;
			if (currentSize <= nSize) {
				return true;
			} else {
				const errorString = `Your image is ${(currentSize / 1024 / 1024).toPrecision(
					2
				)}MB and cannot be uploaded.  Please resize the image to be less than ${
					limit / 1000
				}MB.`;

				return errorString;
			}

			//size is in kb, _value_.size is bytes
		},
	});
	extend('url', {
		validate: value => {
			try {
				new URL(value);
				return true;
			} catch {
				return `Please enter a valid url`;
			}
		},
	});
}
