import { computed } from 'vue';
import useEditorStore from '@/stores/editor';
import { storeToRefs } from 'pinia';

export default function () {
	const editorStore = useEditorStore();
	const { core, teamMembers, pendingOverrides } = storeToRefs(editorStore);
	const currentTabSettings = computed(() => core.value?.ui?.tabs?.team);
	const currentDescription = computed(() => core.value?.office?.team?.description);
	const currentMembers = computed(() =>
		[...teamMembers.value]?.filter(({ associate_id }) => associate_id)
	);
	const currentOrder = computed(() =>
		currentMembers.value?.map(({ associate_id }) => associate_id)
	);

	function tabDisabled({ description = undefined, members = undefined }) {
		const text = description || currentDescription.value;
		const list = members || teamMembers.value;

		return ['', '<p></p>'].includes(text?.trim()) && list?.every(m => m.visible === false);
	}

	const pendingStaffAvatars = computed(() => {
		const filtered = pendingOverrides.value?.filter(
			({ override_type }) => override_type === 'staff_avatar'
		);
		const final = {};
		currentMembers.value?.forEach(member => {
			final[member.associate_id] =
				filtered.find(override => override.subkey === `.${member.associate_id}.image`) ||
				undefined;
		});

		return final;
	});
	function getCurrentMember(associateID) {
		return currentMembers.value.find(member => member.associate_id === associateID);
	}
	return {
		currentMembers,
		currentOrder,
		currentDescription,
		currentTabSettings,
		tabDisabled,
		pendingStaffAvatars,
		getCurrentMember,
	};
}
