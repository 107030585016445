import { customRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { toValue, tryOnScopeDispose } from '@vueuse/shared';

const _cache = new WeakMap();
export function useRouteQuery(name, defaultValue = null, options = {}) {
	const {
		mode = 'replace',
		route = useRoute(),
		router = useRouter(),
		transform = value => value,
	} = options;

	async function updateRouter() {
		const { params, query, hash } = route;

		const finalRouteConfig = {
			params,
			query: { ...query, ...Object.fromEntries(_query.entries()) },
			hash,
		};

		// remove any empty query params
		Object.entries(finalRouteConfig.query).forEach(([key, value]) => {
			if ([undefined, null, ''].includes(value)) {
				delete finalRouteConfig.query[key];
			}
		});

		await router[toValue(mode)](finalRouteConfig)?.catch?.(error => {
			if (
				error.name !== 'NavigationDuplicated' &&
				!error.message.includes('Avoided redundant navigation to current location')
			) {
				console.error(error);
			}
		});
	}

	async function setQuery(newValue) {
		if (_query.get(name) === newValue) {
			return;
		}

		_query.set(name, newValue);

		try {
			_trigger();
		} catch (error) {
			console.error(error);
		} finally {
			await updateRouter();
		}
	}

	if (!_cache.has(route)) {
		_cache.set(route, new Map());
	}

	const _query = _cache.get(route);

	tryOnScopeDispose(async () => {
		await setQuery(null);
	});

	_query.set(name, route.query[name] ?? toValue(defaultValue));

	let _trigger;

	const proxy = customRef((track, trigger) => {
		_trigger = trigger;

		return {
			get() {
				track();

				const data = _query.get(name);

				return transform(data);
			},
			async set(newValue) {
				await setQuery(newValue);
				trigger();
			},
		};
	});
	watch(() => route.query[name], setQuery, { flush: 'sync' });
	// });
	return proxy;
}
